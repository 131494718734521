import "./App.css";
import React, { useState, useRef, useEffect, useCallback } from "react";

function App() {
  const [isDragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [shadowScale, setShadowScale] = useState(1);
  const ballRef = useRef(null);
  const textRef = useRef(null);
  const isoSphereRef = useRef(null);
  const crossSphereRef = useRef(null);
  const [activeDisplay, setActiveDisplay] = useState("2dText");
  const [shadowState, setShadowState] = useState("text");

  // Select diagram
  function select(display) {
    switch (display) {
      case "cross":
        setActiveDisplay("cross");
        setShadowState("");
        break;
      case "isohedron":
        setActiveDisplay("isohedron");
        setShadowState("");
        break;
      case "2d":
        setActiveDisplay("2d");
        setShadowState("ball");
        break;
      case "2dText":
      default:
        setActiveDisplay("2dText");
        setShadowState("text");
        break;
    }
  }

  // Functions to handle drag/touch movements
  const handleStart = (clientX, clientY) => {
    setDragging(true);
    setStartX(clientX);
    setStartY(clientY);
  };

  const handleEnd = () => {
    setDragging(false);
  };

  const handleMove = useCallback(
    (clientX, clientY) => {
      if (isDragging) {
        const deltaX = clientX - startX;
        const deltaY = clientY - startY;
        const sensitivity = 0.2; // Adjust sensitivity as needed

        const newRotationX = rotationX + deltaY * sensitivity;
        const newRotationY = rotationY + deltaX * sensitivity;

        if (shadowState === "ball") {
          setShadowScale(Math.abs(Math.abs(newRotationY % 180) - 90) / 90);
        } else if (shadowState === "text") {
          setShadowScale(Math.abs(Math.abs(newRotationY % 180) - 90) / 90);
        }

        setRotationX(newRotationX);
        setRotationY(newRotationY);

        setStartX(clientX);
        setStartY(clientY);
      }
    },
    [isDragging, startX, startY, rotationX, rotationY, shadowState]
  );

  useEffect(() => {
    const ballElement = ballRef.current;
    const textElement = textRef.current;
    const isoSphereElement = isoSphereRef.current;
    const crossSphereElement = crossSphereRef.current;

    const handleMouseDown = (e) => {
      e.preventDefault(); // Prevent default to avoid issues on mobile devices
      handleStart(e.clientX, e.clientY);
    };

    const handleTouchStart = (e) => {
      if (e.touches.length === 1) {
        e.preventDefault(); // Prevent default to avoid issues on mobile devices
        const touch = e.touches[0];
        handleStart(touch.clientX, touch.clientY);
      }
    };

    const handleMouseUp = () => {
      handleEnd();
    };

    const handleTouchEnd = () => {
      handleEnd();
    };

    const handleMouseMove = (e) => {
      handleMove(e.clientX, e.clientY);
    };

    const handleTouchMove = (e) => {
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        handleMove(touch.clientX, touch.clientY);
      }
    };

    ballElement?.addEventListener("mousedown", handleMouseDown);
    ballElement?.addEventListener("touchstart", handleTouchStart);
    textElement?.addEventListener("mousedown", handleMouseDown);
    textElement?.addEventListener("touchstart", handleTouchStart);
    isoSphereElement?.addEventListener("mousedown", handleMouseDown);
    isoSphereElement?.addEventListener("touchstart", handleTouchStart);
    crossSphereElement?.addEventListener("mousedown", handleMouseDown);
    crossSphereElement?.addEventListener("touchstart", handleTouchStart);

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleTouchEnd);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      ballElement?.removeEventListener("mousedown", handleMouseDown);
      ballElement?.removeEventListener("touchstart", handleTouchStart);
      textElement?.removeEventListener("mousedown", handleMouseDown);
      textElement?.removeEventListener("touchstart", handleTouchStart);
      isoSphereElement?.removeEventListener("mousedown", handleMouseDown);
      isoSphereElement?.removeEventListener("touchstart", handleTouchStart);
      crossSphereElement?.removeEventListener("mousedown", handleMouseDown);
      crossSphereElement?.removeEventListener("touchstart", handleTouchStart);

      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleTouchEnd);

      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [handleMove]);

  return (
    <div>
      <div className="selector-container">
        <button
          className={[
            `selector 
            ${activeDisplay === "2dText" ? "active-selector" : ""}`,
          ]}
          onClick={() => select("2dText")}
        >
          2D Text
        </button>
        <button
          className={[
            `selector 
            ${activeDisplay === "2d" ? "active-selector" : ""}`,
          ]}
          onClick={() => select("2d")}
        >
          2D Circle
        </button>
        <button
          className={[
            `selector 
            ${activeDisplay === "isohedron" ? "active-selector" : ""}`,
          ]}
          onClick={() => select("isohedron")}
        >
          Isohedron Sphere
        </button>
        <button
          className={[
            `selector 
            ${activeDisplay === "cross" ? "active-selector" : ""}`,
          ]}
          onClick={() => select("cross")}
        >
          Cross-Sectional Sphere
        </button>
      </div>
      {/* 2d Circle */}
      {activeDisplay === "2d" && (
        <div
          className={[
            `ball-container ${
              activeDisplay === "2d" ? "active-display" : "hidden-display"
            }`,
          ]}
        >
          <div
            className="ball"
            ref={ballRef}
            style={{
              transform: `rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`,
              transformStyle: "preserve-3d",
            }}
          ></div>
          <div
            className="shadow"
            style={{
              transform: `scale(${shadowScale})`,
              transformStyle: "preserve-3d",
            }}
          ></div>
        </div>
      )}
      {/* 2D Text */}
      {activeDisplay === "2dText" && (
        <div
          className={[
            `text-container ${
              activeDisplay === "2dText" ? "active-display" : "hidden-display"
            }`,
          ]}
        >
          <div
            className="text"
            ref={textRef}
            style={{
              transform: `rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`,
              transformStyle: "preserve-3d",
            }}
          >
            Jonathan Bae
          </div>
          <div className="text-shadow" style={{}}>
            Jonathan Bae
          </div>
        </div>
      )}
      {/* Isohedron Sphere */}
      <div
        className={[
          `scene ${
            activeDisplay === "isohedron" ? "active-display" : "hidden-display"
          }`,
        ]}
      >
        <div
          className="sphere"
          ref={isoSphereRef}
          style={{
            transform: `rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`,
            transformStyle: "preserve-3d",
          }}
        >
          <div className="hemisphere">
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
          </div>
          <div className="hemisphere">
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
            <div className="face"></div>
          </div>
        </div>
      </div>

      {/* Circles as cross */}
      <div
        className={[
          `cross-scene ${
            activeDisplay === "cross" ? "active-display" : "hidden-display"
          }`,
        ]}
      >
        <div className="clip">
          <div
            className="cross-sphere"
            ref={crossSphereRef}
            style={{
              transform: `rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`,
              transformStyle: "preserve-3d",
            }}
          >
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle cross-border"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
